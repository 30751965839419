import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { Container, Header, Icon } from "semantic-ui-react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContributorPageTemplate = ({ data }) => {
  const contributor = data.contributorsYaml
  return (
    <Layout>
      <SEO
        title={contributor.name}
        imageRelativeUrl={contributor.image ? contributor.image.childImageSharp.resize.src : null} 
      />
      <Container style={{ marginTop: "100px", marginBottom: "100px" }}>
        {contributor.image ? (
          <Image
            className="ui rounded image"
            fluid={contributor.image.childImageSharp.fluid}
            alt={contributor.name}
            style={{ float: "right", marginRight: "1rem", width: "200px" }}
          />
        ) : null}
        <Header as="h1">
          <Header.Content>
            {contributor.name}
            <Header.Subheader>{contributor.org}</Header.Subheader>
          </Header.Content>
        </Header>
        <Header as="h2">Role</Header>
        <div dangerouslySetInnerHTML={{ __html: contributor.role }} />
        <Header as="h2">Bio</Header>
        <div dangerouslySetInnerHTML={{ __html: contributor.bio }} />
        <Header as="h2">Contact</Header>
        {contributor.twitter ? (
          <p>
            <Icon disabled name="twitter" />{" "}
            <a href={"https://twitter.com/" + contributor.twitter}>
              @{contributor.twitter}
            </a>
          </p>
        ) : null}
        {contributor.linkedin ? (
          <p>
            <Icon disabled name="linkedin" />{" "}
            <a href={"https://linkedin.com/in/" + contributor.linkedin}>
              {contributor.linkedin}
            </a>
          </p>
        ) : null}
        {contributor.homepage ? (
          <p>
            <Icon disabled name="home" />{" "}
            <a href={contributor.homepage}>{contributor.homepage}</a>
          </p>
        ) : null}
      </Container>
    </Layout>
  )
}

export default ContributorPageTemplate

export const query = graphql`
  query($slug: String!) {
    contributorsYaml(slug: { eq: $slug }) {
      name
      org
      role
      bio
      homepage
      twitter
      linkedin
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            src
          }
          resize(width: 512, height: 512, fit: COVER, cropFocus: CENTER) {
            src
            height
          }
        }
      }
    }
  }
`
